<template>
   <div class="electronicScaleSetup">
        <div class="scroll-box">
            <div class="tr-li">
                <div class="lable-txt"></div>
                <div class="from-input">
                    <el-checkbox v-model="isEnableScale" label="启用电子秤功能"></el-checkbox>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">电子秤品牌:</div>
                <div class="from-input">
                    <el-select v-model="brand" placeholder="请选择" >
                        <el-option label="海石Histone-HT526R" :value="2" > </el-option>
                        <el-option label="中科英泰s343" :value="1" > </el-option>
                        <!-- <el-option label="寺冈DS-673" :value="3" > </el-option> -->
                    </el-select>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">端口:</div>
                <div class="from-input">
                    <el-select v-model="port" placeholder="请选择" >
                            <el-option  :label="'COM'+item" :value="item"  v-for="item in 8" :key="item"></el-option>
                        </el-select>
                </div>
            </div>
            <div class="tr-li">
                <div class="lable-txt">波特率:</div>
                <div class="from-input">
                    <el-select v-model="baud" placeholder="请选择" >
                        <el-option  :label="9600" value="9600" ></el-option>
                        <el-option  :label="19200" value="19200" ></el-option>
                        <el-option  :label="38400" value="38400" ></el-option>
                        <el-option  :label="57600" value="57600" ></el-option>
                        <el-option  :label="115200" value="115200" ></el-option>
                    </el-select>           
                </div>
            </div>
            
            <div class="tr-li">
                <div class="lable-txt"></div>
                <div class="from-input">
                    <el-checkbox v-model="isTranJin" label="启用将电子秤称重数转为市斤"></el-checkbox>
                    <p class="red">例：电子秤为公斤秤，菜品数量 = 电子秤称重数 ╳ 2</p>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="back();">返回</button>
            <button class="btn selected" @click="saveConfig()">保存配置</button>
        </div>
   </div>
</template>

<script>
export default {
    name:'electronicScaleSetup',
    data(){
        return {
            /**电子秤品牌*/
            brand:2,//1 中科英泰s343  2 海石Histone-HT526R
            port:1,
            baud:9600,
            //启用将电子秤称重数转为市斤
            isTranJin:false,
            //是否启用电子秤功能
            isEnableScale:false,
        }
    },
    mounted(){
        if(this.$webBrowser.isMobile.Windows){
            this.brand=1;//pc  默认 中科英泰s343
        }

        this.$webBrowser.getElectronicScale().then(d=>{//获取电子秤配置信息
            if(d){
                this.isEnableScale=d.isEnableScale==true;
                this.brand=d.brand||this.brand;
                this.port=d.port||1,
                this.baud=parseInt(d.baud)||9600,
                this.isTranJin=d.isTranJin==true;
                //开启电子秤
                this.openPosScale();
            }
        });
    },
    methods:{
        /**退出 */
        back(){
            this.$emit("close");
        },
        /**保存配置 */
        saveConfig(){
            let data={
                brand:this.brand,
                port:this.port,
                baud:this.baud,
                isTranJin:this.isTranJin,
                isEnableScale:this.isEnableScale
            }
            this.$webBrowser.saveElectronicScale(data).then(d=>{
                if(d && d.state==0){
                    this.$message.success("保存成功");
                }
            });
        }
    }
}
</script>

<style lang="scss">
@import './electronicScaleSetup.scss';
</style>